import React from "react";
import "./Hero.css";

import Header from "../Header/Header";

import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";

import NumberCounter from "number-counter";

import { motion } from "framer-motion";

const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  const mobile = window.innerWidth < -768 ? true : false;

  return (
    <div className="hero" id="home">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile ? "165px" : "238px" }}
            whileInView={{ left: "8px" }}
            transition={{ duration: 1, type: "tween" }}
          ></motion.div>
          <span>Venha treinar com quem entende!</span>
        </div>
        <div className="hero-text">
          <div>
            <span className="stroke-text">Construa seu </span>
            {/* <span>seu</span> */}
          </div>
          <div>
            <span>corpo ideal</span>
          </div>
          <div>
            <span>
              Aqui nós iremos te ajudar a moldar e construir o seu corpo ideal e
              viver a sua vida com energia máxima.
            </span>
          </div>
        </div>

        <div className="figures">
          <div>
            <span>
              <NumberCounter end={45} start={0} delay={"2"} preFix="+"/>
            </span>
            <span>Treinadores</span>
          </div>
          <div>
            <span><NumberCounter end={965} start={500} delay={"2"} preFix="+"/></span>
            <span>Membros</span>
          </div>
          <div>
            <span><NumberCounter end={30} start={0} delay={"2"} preFix="+"/></span>
            <span>Modalidades</span>
          </div>
        </div>

        <div className="hero-buttons">
          <buttons className="btn">Matricule-se</buttons>
          <buttons className="btn">Saber mais</buttons>
        </div>
      </div>

      <div className="right-h">
        <button className="btn">Matricule-se</button>

        <motion.div
          initial={{ right: "-1rem" }}
          whileInView={{ right: "4rem" }}
          transition={transition}
          className="heart-rate"
        >
          <img src={Heart} alt="" />
          <span>Batimentos</span>
          <span>116 bpm</span>
        </motion.div>

        <img src={hero_image} alt="" className="hero-image" />
        <motion.img
          initial={{ right: "11rem" }}
          whileInView={{ right: "20rem" }}
          transition={transition}
          src={hero_image_back}
          alt=""
          className="hero-image-back"
        />

        <motion.div
          initial={{ right: "37rem" }}
          whileInView={{ right: "28rem" }}
          transition={transition}
          className="calories"
        >
          <img src={Calories} alt="" />
          <div>
            <span>Calorias Queimadas</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
