import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "Fiz a escolha certa ao entrar na Fitclub. Com o plano e programa correto para mim, alcancei meu corpo ideal",
    name: 'FERNANDO ALMEIDA',
    status : 'ADVOGADO'
  },
  {
    image: image2,
    review: 'Tentei várias academias, mas nunca me senti confortável e seguro como me sinto na Fitclub',
    name: 'JOÃO FERNANDES',
    status: 'ADMINISTRADOR DE EMPRESAS'
  },
  {
    image : image3,
    review:'Sinto que na Fitclub tenho o acompanhamento ideal para continuar me desenvolvendo da forma correta',
    name: 'Marcos Lettiere',
    status: "Desenvolvedor de Sistemas"
  }
];
